<script setup>
import { useForm } from 'vee-validate';
import { storeToRefs } from 'pinia';
import { useSideBar } from '~/stores/sideBar';
import { useAuthStore } from '~/stores/auth';
import { useCenterStore } from '~/stores/centerDetails';
import { useMessageStore } from '~/stores/messages';
import { trackPageLoad } from '~/composables/useTracking';

const props = defineProps({
    preferences: {
        type: Array,
        required: true
    }
})

// Load user data
const authStore = useAuthStore();
const { handleUserData } = authStore;
const { statusObj, currentUser } = storeToRefs(authStore);

// Prepare form
const { values, meta, handleSubmit, isSubmitting } = useForm({
    firstName: '',
    lastName: '',
    salutationCode: '',
    billingAddress: {
        postalCode: ''
    },
    birthDate: '',
    preferences: ''
});

const {
    _firstNameConfig,
    _lastNameConfig,
    _birthdayConfig,
    _genderConfig,
    _postalCodeConfig,
    getUserPreferences
} = await useFormFieldConfigs();

// The preferences the user might have already entered (in case of edit)
const consumerProfilePreferences = computed(() => currentUser.value?.preferences?.interests || []);

// Define config for form elements
const userPreferences = getUserPreferences(props?.preferences);

// Global
const { toggleSideBarLayer } = useSideBar();

// Center store
const { centerUid, centerName } = storeToRefs(useCenterStore());

// Fetch messages
const messagesStore = useMessageStore();
const { messages: staticKeys } = storeToRefs(messagesStore);

// Dynamic values
const isActive = computed(() => {
    if (!values) {
        return false;
    }
    return objContainsValues(values) && meta.value?.valid;
});

const isFormSubmitting = computed(() => isSubmitting.value || statusObj.value?.success);

const getFormClass = computed(() => {
    return {
        'is-submitting': isFormSubmitting.value
    }
});

const buttonConfig = ref({
    fieldType: 'submit',
    active: isActive,
    visible: true,
    large: true,
    isPending: isFormSubmitting,
    label: staticKeys.value?.account_form_button_label_savechanges
});

const onSubmit = handleSubmit(async (values) => {
    await handleUserData(
        {
            method: 'PATCH',
            payload: transformObj(values),
            trackingPayload: {
                preferences: {
                    center_id: centerUid.value,
                    center_name: centerName.value,
                    register_form_user_data: getFormattedTrackingData(values)
                },
                onBoarding: {
                    center_id: centerUid.value,
                    center_name: centerName.value,
                    step_id: '6',
                    step_action: 'proceed'
                }
            },
            successMessage: staticKeys.value?.account_form_personaldata_success_message,
            scrollDiv: document.querySelector('.sidebar-content-inner')
        }
    );
});

// Close layer after 3 sec
watchEffect(() => {
    if (statusObj.value?.success) {
        setTimeout(() => {
            toggleSideBarLayer(false)
        }, 1000);
    }
});

// Tracking Layer
trackPageLoad('Register_Preferences', {
    center_id: centerUid.value,
    center_name: centerName.value
});
</script>

<template>
    <SideBarLayer>
        <SideBarWrapper>
            <form class="user-account-form-content" :class="getFormClass" @submit.prevent="onSubmit">
                <SideBarHeader>
                    <SideBarMainHeadline :text="staticKeys?.account_form_personaldata_headline_markdown" />
                </SideBarHeader>

                <FormMessage :status-obj="statusObj" />

                <FormSection>
                    <p class="user-account-form-intro">
                        {{ staticKeys?.account_form_salutation_headline }}
                        <span class="user-account-form-asterisk">
                            {{ staticKeys?.account_form_asterisk }}.
                        </span>
                    </p>

                    <FormText :config="_firstNameConfig" />
                    <FormText :config="_lastNameConfig" />
                </FormSection>

                <FormSection>
                    <p class="user-account-form-intro">
                        {{ staticKeys?.account_form_gender_intro }}
                    </p>

                    <p class="user-account-form-label">
                        {{ staticKeys?.account_form_gender_headline }}
                    </p>

                    <FormRadioGroup>
                        <FormRadio :config="_genderConfig?.female" />
                        <FormRadio :config="_genderConfig?.male" />
                        <FormRadio :config="_genderConfig?.diverse" />
                    </FormRadioGroup>
                </FormSection>

                <FormSection>
                    <p class="user-account-form-intro">
                        {{ staticKeys?.account_form_birthday_headline }}
                    </p>

                    <FormText :config="_birthdayConfig" />
                </FormSection>
                <FormSection>
                    <p class="user-account-form-intro">
                        {{ staticKeys?.account_form_postalcode_headline }}
                    </p>

                    <FormText :config="_postalCodeConfig" />
                </FormSection>

                <FormSection>
                    <p class="user-account-form-intro">
                        {{ staticKeys?.account_form_preferences_headline }}:
                    </p>

                    <FormCheckBoxGroup>
                        <FormCheckBox
                            v-for="item in userPreferences"
                            :key="item.id"
                            :config="{ ...item, ...{val: consumerProfilePreferences}, ...{pending: isSubmitting}}"
                        />
                    </FormCheckBoxGroup>
                </FormSection>

                <FormButton :config="buttonConfig" class="" />
            </form>
        </SideBarWrapper>
    </SideBarLayer>
</template>

<style lang="scss">
.user-account-form-intro {
    @include body2-medium;

    margin: 0 0 20px;
}

.user-account-form-label {
    @include form-label;
}

.user-account-form-asterisk {
    @include form-asterisk;
}

.user-account-form-content {
    &::after {
        content: '';
        transition: opacity 400ms ease;
        opacity: 0;
    }

    &.is-submitting {
        @include form-is-submitting;
    }
}
</style>
